import React, { Component } from "react";
import Layout from "../components/layouts/Layout";
import SEO from "../components/SEO";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import MediaQuery from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faAndroid } from "@fortawesome/free-brands-svg-icons";
import timelineCropped from "../images/timeline-cropped.png";
import leaderboard from "../images/leaderboard.png";
import anonymous from "../images/anonymous.png";
import business from "../images/business.png";
import mockups from "../images/mockups.png";
import HomeEmailForm from "../components/HomeEmailForm";

class Index extends Component {

	render() {
		return (
			<Layout black={true}>
				<SEO
					title={"Jeti | What's Happening Nearby"}
					description={"Jeti is a social media platform that connects you with people who are nearby, have shared interests or topics, and friends and family members. Our mission is to provide a fun, friendly way to engage with your community, near or far."}
				/>
				<div className={"container mx-auto font-hero"}>
					<div className={"flex mt-10 lg:mt-32 flex-col lg:flex-row"}>
						<div className={"lg:w-2/3 flex justify-center flex-col px-8 xl:px-0 lg:pr-20 text-center lg:text-left md:w-2/3 md:mx-auto"}>
							<span className={"text-white font-bold text-3xl lg:text-5xl xl:text-6xl leading-none xl:pr-10"}>
								See what everyone's saying around you
							</span>
							<span className={"text-white font-medium text-md lg:text-xl lg:w-1/2 mt-8 lg:mt-10"}>
								Jeti is a social media platform that shows you posts from people 6-12 miles away.
							</span>
							<span className={"text-gray-500 font-regular text-md lg:text-xl mt-5"}>
								<span className={"text-secondary"}>
									Early access&nbsp;
								</span>
								available for iOS and Android.
							</span>
							<div className={"mt-5 lg:mt-10 flex-row flex justify-center lg:justify-start"}>
								<OutboundLink
									href={"https://apps.apple.com/us/app/id1523579231"}
									target={"_blank"}
									className={"inline-block font-semibold text-3xl px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-secondary hover:bg-white mt-4 lg:mt-0"}
								>
									iOS
									<MediaQuery minWidth={"1000px"}>
										<FontAwesomeIcon
											icon={faApple}
											size={"1x"}
											className={"ml-4"}
										/>
									</MediaQuery>
								</OutboundLink>
								<OutboundLink
									href={"https://play.google.com/store/apps/details?id=app.jeti"}
									target={"_blank"}
									className={"ml-4 inline-block font-semibold text-3xl px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-secondary hover:bg-white mt-4 lg:mt-0"}
								>
									Android
									<MediaQuery minWidth={"1000px"}>
										<FontAwesomeIcon
											icon={faAndroid}
											size={"1x"}
											className={"ml-4 pt-1"}
										/>
									</MediaQuery>
								</OutboundLink>
							</div>
						</div>
						<div className={"mt-10 lg:mt-0 lg:w-1/3 px-5"}>
							<img
								src={timelineCropped}
							/>
						</div>
					</div>
					<div className={"py-10 w-3/4 border-b-2 mx-auto border-gray-800"}/>
					<div className={"flex items-center justify-center align-center my-10"}>
						<span className={"text-gray-400 text-lg text-center px-5"}>
							Download
							<span className={"text-primary font-bold"}>
								&nbsp;Jeti Early Access&nbsp;
							</span>
							and claim your username before it's too late.
						</span>
					</div>
					<div className={"w-3/4 border-b-2 mx-auto border-gray-800"}/>
					<div className={"flex items-center justify-center align-center my-20"}>
						<iframe width="560" height="315" src="https://www.youtube.com/embed/fSzhQWcYaOE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</div>
					<div className={"w-3/4 border-b-2 mx-auto border-gray-800"}/>
					<div className={"flex mt-12 lg:mt-24 w-3/4 mx-auto flex-col lg:flex-row"}>
						<div className={"lg:w-1/2"}>
							<img
								src={leaderboard}
							/>
						</div>
						<div className={"mt-10 lg:mt-0 lg:w-1/2 flex justify-center flex-col lg:px-20 flex items-center"}>
							<span className={"text-white font-bold text-4xl text-left leading-none text-center"}>
								The Leaderboard
							</span>
							<span className={"text-white font-medium text-xl text-left mt-5 text-center"}>
								Cities and universities around the world are ranked based on how many upvotes they received in the past week.
							</span>
						</div>
					</div>
					<div className={"py-5 lg:py-10 w-3/4 border-b-2 mx-auto border-gray-800"}/>
					<div className={"flex mt-12 lg:mt-24 w-3/4 mx-auto flex-col lg:flex-row"}>
						<div className={"mt-10 lg:mt-0 lg:w-1/2 flex justify-center flex-col lg:px-20 flex items-center"}>
							<span className={"text-white font-bold text-4xl text-left leading-none text-center"}>
								Post Anonymously
							</span>
							<span className={"text-white text-xl text-left mt-5 text-center"}>
								Start posting on Jeti without creating an account. Ask for advice, post jokes, or break local news.
							</span>
						</div>
						<div className={"mt-10 lg:mt-0 lg:w-1/2"}>
							<img
								src={anonymous}
							/>
						</div>
					</div>
					<div className={"py-5 lg:py-10 w-3/4 border-b-2 mx-auto border-gray-800"}/>
					<div className={"flex mt-12 lg:mt-24 w-3/4 mx-auto flex-col lg:flex-row"}>
						<div className={"lg:w-1/2"}>
							<img
								src={business}
							/>
						</div>
						<div className={"mt-10 lg:mt-0 lg:w-1/2 flex justify-center flex-col lg:px-20 flex items-center"}>
							<span className={"text-white font-bold text-4xl text-left leading-none text-center"}>
								Raise Awareness
							</span>
							<span className={"text-white text-xl text-left mt-5 text-center"}>
								Share your story with everyone nearby. Create an account and start building your following.
							</span>
						</div>
					</div>
					{/* <div className={"py-5 lg:py-10 w-3/4 border-b-2 mx-auto border-gray-800"}/>
					<HomeEmailForm/> */}
					<div className={"py-5 lg:py-10 w-3/4 border-b-2 mx-auto border-gray-800"}/>
					<div className={"flex mt-20 w-3/4 mx-auto flex-col mb-16"}>
						<div className={"lg:w-2/3 mx-auto lg:mb-20 flex justify-center flex-col lg:px-20 flex items-center"}>
							<span className={"text-white font-bold text-4xl text-left leading-none text-center"}>
								Download Jeti Early Access
							</span>
							<div className={"mt-10 flex justify-center"}>
								<OutboundLink
									href={"https://apps.apple.com/us/app/id1523579231"}
									target={"_blank"}
									className={"inline-block font-semibold text-3xl px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-secondary hover:bg-white mt-4 lg:mt-0"}
								>
									iOS
									<MediaQuery minWidth={"1000px"}>
										<FontAwesomeIcon
											icon={faApple}
											size={"1x"}
											className={"ml-4"}
										/>
									</MediaQuery>
								</OutboundLink>
								<OutboundLink
									href={"https://play.google.com/store/apps/details?id=app.jeti"}
									target={"_blank"}
									className={"ml-4 inline-block font-semibold text-3xl px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-secondary hover:bg-white mt-4 lg:mt-0"}
								>
									Android
									<MediaQuery minWidth={"1000px"}>
										<FontAwesomeIcon
											icon={faAndroid}
											size={"1x"}
											className={"ml-4 pt-1"}
										/>
									</MediaQuery>
								</OutboundLink>
							</div>
						</div>
						<MediaQuery minWidth={"1000px"}>
							<div className={""}>
								<img
									src={mockups}
								/>
							</div>
						</MediaQuery>
					</div>
				</div>
			</Layout>
		);
	}

}

export default Index;
